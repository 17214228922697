import {
  Alert,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  Input,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Pagination } from "../../../Components/Pagination";
import Navbar from "../../../Components/Navbar";

import { useAuth } from "../../../Context/AuthProvider";
import { format } from "date-fns";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import { MdDeleteForever } from "react-icons/md";
import axios from "axios";
import { backendBaseURL } from "../../../AppConstants";

const RepaymentLists = () => {
  const ButtonStyle = {
    size: "xs",
    fontWeight: "bold",
    backgroundColor: "white",
    _hover: { backgroundColor: "white" },
  };

  const { fetchrePaymentList, financerNamesList,bankNamesList } = useAuth();
  const toast = useToast();
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  let perPage = 10;
  let totalPage = Math.ceil(fetchrePaymentList.length / perPage);
  let end = page * perPage;
  let start = end - perPage;
  let reverseData = [...fetchrePaymentList].slice(start, end);
  const [searchQuery, setSearchQuery] = useState("");
  const filteredData = reverseData.filter((el) => {
    const financerOrganizationName = el?.financerOrganizationName || "";
    const transactionRefNo = el?.transactionRefNo || "";
    const paymentThrough = el?.paymentThrough || "";
    const netAmountPayable = el?.netAmountPayable || "";
    const totalAmount = el?.totalAmount || "";
    return (
      financerOrganizationName
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      transactionRefNo.toLowerCase().includes(searchQuery.toLowerCase()) ||
      paymentThrough.toLowerCase().includes(searchQuery.toLowerCase()) ||
      netAmountPayable.toLowerCase().includes(searchQuery.toLowerCase()) ||
      totalAmount.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  const handleDeleteRepayments = async (_id) => {
    try {
      await axios.delete(`${backendBaseURL}/deleterePaymentDetails/${_id}`);

      toast({
        position: "top",
        title: "Repayments Details Deleted Successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      toast({
        position: "top",
        title: "Error Deleting Repayments Details",
        description: `${error}`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const {
    isOpen: isSidebarOpen,
    onOpen: onSidebarOpen,
    onClose: onSidebarClose,
  } = useDisclosure();

  const InputStyleEdit = {
    borderRadius: "md",
    borderWidth: "2px",
    borderColor: "gray.300",
    py: "2",
    px: "4",
    variant: "unstyled",
  };

  const BoxStyle = {
    position: "absolute",
    top: "-8px",
    left: "8px",
    pointerEvents: "none",
    color: "teal",
    fontSize: "sm",
    bg: "white",
    px: "1",
  };

  const [selectedItem, setSelectedItem] = useState(null);
  // console.log("selected", selectedItem);
  const [editRepayment, setEditRepayment] = useState({
    financerOrganizationName: "",
    transactionRefNo: "",
    netAmountPayable: "",
    additionalCharges: "",
    totalAmount: "",
    repaymentDate: "",
    repaymentCurrency: "",
    reExchangeRate: "",
    amountInINR: "",
    bankCharges: "",
    paymentThrough: "",
  });

  useEffect(() => {
    const { totalAmount, reExchangeRate } = editRepayment;

    if (!isNaN(totalAmount) && !isNaN(reExchangeRate)) {
      const calculatedAmount = parseFloat(totalAmount) * parseFloat(reExchangeRate);
      setEditRepayment((prevState) => ({
        ...prevState,
        amountInINR: calculatedAmount.toFixed(2), // Fixes the result to 2 decimal places
      }));
    }
  }, [editRepayment.totalAmount, editRepayment.reExchangeRate]);

  const handleRepaymentUpdate = async (_id) => {
    try {
      const apiEndpoint = `${backendBaseURL}/editrePaymentDetails/${_id}`;
      const response = await axios.patch(apiEndpoint, editRepayment);
      if (response.status !== 200) {
        throw new Error("Failed to update finance details");
      }
      setSelectedItem(null);
      setEditRepayment({
        financerOrganizationName: "",
        transactionRefNo: "",
        netAmountPayable: "",
        additionalCharges: "",
        totalAmount: "",
        repaymentDate: "",
        repaymentCurrency: "",
        reExchangeRate: "",
        amountInINR: "",
        bankCharges: "",
        paymentThrough: "",
      });
      toast({
        position: "top",
        title: "Finance Details Updated Successfully",
        description: "",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      toast({
        position: "top",
        title: "Error Updating Finance Details",
        description: "An error occurred while updating the Finance Details.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleEditRepayment = (el) => {
    setSelectedItem(el);
    setEditRepayment({
      financerOrganizationName: el.financerOrganizationName,
      transactionRefNo: el.transactionRefNo,
      netAmountPayable: el.netAmountPayable,
      additionalCharges: el.additionalCharges,
      totalAmount: el.totalAmount,
      repaymentDate: el.repaymentDate,
      repaymentCurrency: el.repaymentCurrency,
      reExchangeRate: el.reExchangeRate,
      amountInINR: el.amountInINR,
      bankCharges: el.bankCharges,
      paymentThrough: el.paymentThrough,
    });
  };

  return (
    <Box
      pl={{ base: "2", md: "10" }}
      pt={{ base: "10", md: "10" }}
      w={{ base: "100%", md: "80%" }}
    >
      <Navbar />
      <br />

      <Flex
        direction={{ base: "column", md: "row" }}
        justifyContent={{ base: "flex-start", md: "space-between" }}
        mb={5}
      >
        <Flex justifyContent={"flex-start"} gap={5}>
          <IoMdArrowRoundBack
            onClick={() => navigate("/finance")}
            size={20}
            style={{ marginTop: 5 }}
          />
          <Heading textAlign={"start"} size={"md"} color={"teal.400"}>
            Repayment List
          </Heading>
        </Flex>
        <Input
          placeholder="Search"
          w={300}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </Flex>
      <Flex
        direction={{ base: "column", md: "row" }}
        justifyContent={{ base: "flex-start", md: "space-between" }}
      >
        <Box
          w={{ base: "100%", md: "100%" }}
          p={{ base: "5", md: "5" }}
          borderRadius={10}
          boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"}
        >
          <TableContainer>
            <Table variant="simple" colorScheme="teal" boxShadow={"md"}>
              <Thead>
                <Tr boxShadow={"sm"}>
                  <Th
                    boxShadow={"sm"}
                    style={{ textAlign: "center", color: "teal" }}
                  >
                    Financer Name
                  </Th>
                  <Th
                    boxShadow={"sm"}
                    style={{ textAlign: "center", color: "teal" }}
                  >
                    Transaction Ref No
                  </Th>
                  <Th
                    boxShadow={"sm"}
                    style={{ textAlign: "center", color: "teal" }}
                  >
                    Net Amount Payable
                  </Th>
                  <Th
                    boxShadow={"sm"}
                    style={{ textAlign: "center", color: "teal" }}
                  >
                    Additional Charges
                  </Th>
                  <Th
                    boxShadow={"sm"}
                    style={{ textAlign: "center", color: "teal" }}
                  >
                    Total Amount
                  </Th>
                  <Th
                    boxShadow={"sm"}
                    style={{ textAlign: "center", color: "teal" }}
                  >
                    Payment Through
                  </Th>
                  <Th
                    boxShadow={"sm"}
                    style={{ textAlign: "center", color: "teal" }}
                  >
                    Repayment Date
                  </Th>
                  <Th
                    boxShadow={"sm"}
                    style={{ textAlign: "center", color: "teal" }}
                  >
                    Currency
                  </Th>
                  <Th
                    boxShadow={"sm"}
                    style={{ textAlign: "center", color: "teal" }}
                  >
                    Exchange Rate
                  </Th>
                  <Th
                    boxShadow={"sm"}
                    style={{ textAlign: "center", color: "teal" }}
                  >
                    Amount In INR
                  </Th>
                  <Th
                    boxShadow={"sm"}
                    style={{ textAlign: "center", color: "teal" }}
                  >
                    Bank Charges
                  </Th>
                  <Th
                    boxShadow={"sm"}
                    style={{ textAlign: "center", color: "teal" }}
                  >
                    Action
                  </Th>
                </Tr>
              </Thead>

              <Tbody>
                {filteredData.length > 0 &&
                  filteredData?.map((el) => (
                    <Tr key={el._id} boxShadow={"md"}>
                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>
                          {el.financerOrganizationName}
                        </Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>{el.transactionRefNo}</Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>{el.netAmountPayable}</Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>{el.additionalCharges}</Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>{el.totalAmount}</Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>{el.paymentThrough}</Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>
                          {format(new Date(el.repaymentDate), "dd-MM-yyyy")}
                        </Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>{el.repaymentCurrency}</Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>{el.reExchangeRate}</Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>{el.amountInINR}</Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>{el.bankCharges}</Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Flex gap={3}>
                          <FiEdit
                            style={{ color: "teal", marginTop: 2 }}
                            onClick={() => {
                              onSidebarOpen();
                              handleEditRepayment(el);
                            }}
                          />
                          <Popover>
                            <PopoverTrigger>
                              <Button
                                size={"xs"}
                                color="white"
                                bgColor={"white"}
                                _hover={{ bgColor: "white" }}
                              >
                                <MdDeleteForever
                                  style={{ color: "red" }}
                                  size={18}
                                />
                              </Button>
                            </PopoverTrigger>
                            <PopoverContent>
                              <PopoverArrow />
                              <PopoverCloseButton />
                              <PopoverBody mt={5}>
                                Are you sure you want to delete <br />
                                repayments?
                              </PopoverBody>
                              <PopoverFooter
                                display="flex"
                                justifyContent="flex-end"
                                gap={2}
                              >
                                <ButtonGroup size="xs"></ButtonGroup>
                                <ButtonGroup size="xs">
                                  <Button
                                    color="green"
                                    _hover={{
                                      color: "white",
                                      bgColor: "green",
                                    }}
                                    onClick={() =>
                                      handleDeleteRepayments(el._id)
                                    }
                                    fontWeight={"bold"}
                                  >
                                    Yes
                                  </Button>
                                </ButtonGroup>
                              </PopoverFooter>
                            </PopoverContent>
                          </Popover>
                        </Flex>
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
            {selectedItem && (
              <Box>
                <Drawer
                  isOpen={isSidebarOpen}
                  onClose={onSidebarClose}
                  placement="right"
                  size={"lg"}
                >
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleRepaymentUpdate(selectedItem._id);
                    }}
                  >
                    <DrawerOverlay />
                    <DrawerContent>
                      <DrawerCloseButton />
                      <DrawerHeader>Edit Repayment Details</DrawerHeader>
                      <DrawerBody>
                        <Box position="relative" mb={5}>
                          <Select
                            name="financerOrganizationName"
                            value={editRepayment.financerOrganizationName || ""}
                            onChange={(e) =>
                              setEditRepayment({
                                ...editRepayment,
                                financerOrganizationName: e.target.value,
                              })
                            }
                          >
                            <option value="" disabled>
                              Financer Name
                            </option>
                            {financerNamesList.map((financer) => (
                              <option key={financer} value={financer}>
                                {financer}
                              </option>
                            ))}
                          </Select>
                          <Box {...BoxStyle}>Financer Name</Box>
                        </Box>

                        <Box position="relative">
                          <Input
                            {...InputStyleEdit}
                            type="text"
                            value={editRepayment.transactionRefNo}
                            onChange={(e) =>
                              setEditRepayment({
                                ...editRepayment,
                                transactionRefNo: e.target.value,
                              })
                            }
                          />
                          <Box {...BoxStyle}>Transaction Ref No</Box>
                        </Box>
                        <br />
                        <Box position="relative" mb={5}>
                          <Select
                            name="paymentThrough"
                            value={editRepayment.paymentThrough || ""}
                            onChange={(e) =>
                              setEditRepayment({
                                ...editRepayment,
                                paymentThrough: e.target.value,
                              })
                            }
                          >
                            <option value="" disabled>
                              Payment Through
                            </option>
                            {bankNamesList.map((financer) => (
                              <option key={financer} value={financer}>
                                {financer}
                              </option>
                            ))}
                          </Select>
                          <Box {...BoxStyle}>Payment Through</Box>
                        </Box>
                        <Box position="relative">
                          <Input
                            {...InputStyleEdit}
                            type="text"
                            value={editRepayment.netAmountPayable}
                            onChange={(e) =>
                              setEditRepayment({
                                ...editRepayment,
                                netAmountPayable: e.target.value,
                              })
                            }
                          />
                          <Box {...BoxStyle}>Net Amount Payable</Box>
                        </Box>
                        <br />
                        <Box position="relative">
                          <Input
                            {...InputStyleEdit}
                            type="text"
                            value={editRepayment.additionalCharges}
                            onChange={(e) =>
                              setEditRepayment({
                                ...editRepayment,
                                additionalCharges: e.target.value,
                              })
                            }
                          />
                          <Box {...BoxStyle}>Additional Charges</Box>
                        </Box>
                        <br />
                        <Box position="relative">
                          <Input
                            {...InputStyleEdit}
                            type="text"
                            value={editRepayment.totalAmount}
                            onChange={(e) =>
                              setEditRepayment({
                                ...editRepayment,
                                totalAmount: e.target.value,
                              })
                            }
                          />
                          <Box {...BoxStyle}>Total Amount</Box>
                        </Box>
                        <br />
                        <Box position="relative">
                          <Input
                            {...InputStyleEdit}
                            type="date"
                            value={
                              editRepayment.repaymentDate
                                ? editRepayment.repaymentDate.slice(0, 10)
                                : ""
                            }
                            onChange={(e) =>
                              setEditRepayment({
                                ...editRepayment,
                                repaymentDate: e.target.value,
                              })
                            }
                          />
                          <Box {...BoxStyle}>Repayment Date</Box>
                        </Box>
                        <br />

                        <Box position="relative" mb={5}>
                          <Select
                            name="repaymentCurrency"
                            value={editRepayment.repaymentCurrency || ""}
                            onChange={(e) =>
                              setEditRepayment({
                                ...editRepayment,
                                repaymentCurrency: e.target.value,
                              })
                            }
                          >
                            <option value="" disabled>
                              Currency
                            </option>
                            <option value="INR">INR</option>
                            <option value="USD">USD</option>
                            <option value="EUR">Euro</option>
                            <option value="QAR">Qatar Riyal</option>
                            <option value="SAR">Saudi Riyal</option>
                          </Select>
                          <Box {...BoxStyle}>Currency</Box>
                        </Box>
                     
                        <Box position="relative">
                          <Input
                            {...InputStyleEdit}
                            type="text"
                            value={editRepayment.reExchangeRate}
                            onChange={(e) =>
                              setEditRepayment({
                                ...editRepayment,
                                reExchangeRate: e.target.value,
                              })
                            }
                          />
                          <Box {...BoxStyle}>Exchange Rate</Box>
                        </Box>
                     
                        <br />
                        <Box position="relative">
                          <Input
                            {...InputStyleEdit}
                            type="text"
                            value={editRepayment.amountInINR}
                            onChange={(e) =>
                              setEditRepayment({
                                ...editRepayment,
                                amountInINR: e.target.value,
                              })
                            }
                            readOnly
                          />
                          <Box {...BoxStyle}>Amount In INR</Box>
                        </Box>
                     
                        <br />
                        <Box position="relative">
                          <Input
                            {...InputStyleEdit}
                            type="text"
                            value={editRepayment.bankCharges}
                            onChange={(e) =>
                              setEditRepayment({
                                ...editRepayment,
                                bankCharges: e.target.value,
                              })
                            }
                          />
                          <Box {...BoxStyle}>Bank Charges</Box>
                        </Box>
                       
                        
                      </DrawerBody>
                      <DrawerFooter>
                        <Button
                          // onClick={handleFinanceUpdate}
                          type="submit"
                          bgGradient="linear(to-r, teal.300,  teal.100)"
                          _hover={{
                            bgGradient: "linear(to-r, teal.100,  teal.400)",
                          }}
                        >
                          Update Repayment Details
                        </Button>
                      </DrawerFooter>
                    </DrawerContent>
                  </form>
                </Drawer>
              </Box>
            )}
            <Box>
              {filteredData.length === 0 && (
                <Alert
                  status="info"
                  variant="subtle"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  mt={4}
                >
                  <AlertIcon boxSize={8} />
                  No Record Found
                </Alert>
              )}
            </Box>
          </TableContainer>
          <Flex justifyContent={"flex-end"} mt={5}>
            <Pagination page={page} setPage={setPage} totalPage={totalPage} />
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

export default RepaymentLists;
