import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Heading,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Pagination } from "../../../Components/Pagination";
import Navbar from "../../../Components/Navbar";
import { Link } from "react-router-dom";
import { IoPieChart } from "react-icons/io5";
import { useAuth } from "../../../Context/AuthProvider";
import { PiMinusCircleBold, PiPlusCircleBold } from "react-icons/pi";
const Dashboard = () => {
  const ButtonStyle = {
    size: "xs",
    fontWeight: "bold",
    backgroundColor: "white",
    _hover: { backgroundColor: "white" },
  };

  const { paginationData, shippingDetailsData, fetchfinancerNamesList } =
    useAuth();

  //console.log("shipingdetails", shippingDetailsData);

  // const [page, setPage] = useState(1);
  // let perPage = 10;
  // let totalPage = Math.ceil(shippingDetailsData.length / perPage);
  // let end = page * perPage;
  // let start = end - perPage;
  // let reverseData = [...shippingDetailsData].slice(start, end);
  // const [searchQuery, setSearchQuery] = useState("");

  // const filteredData = reverseData.filter((el) => {
  //   const { salesContractNumber, shipmentWiseData, salesContract } = el;
  //   const exporterName = shipmentWiseData?.exporterName || "";
  //   const hssName = shipmentWiseData?.hssName || "";
  //   const status =
  //     shipmentWiseData?.remainingQuantity === "0" ? "Completed" : "Pending";
  //   const salesContractContactPersonName =
  //     salesContract?.salesContractContactPersonName || "";
  //   const importingFirmName = salesContract?.importingFirmName || "";
  //   return (
  //     (salesContractNumber &&
  //       salesContractNumber
  //         .toLowerCase()
  //         .includes(searchQuery.toLowerCase())) ||
  //     exporterName.toLowerCase().includes(searchQuery.toLowerCase()) ||
  //     hssName.toLowerCase().includes(searchQuery.toLowerCase()) ||
  //     status.toLowerCase().includes(searchQuery.toLowerCase()) ||
  //     salesContractContactPersonName
  //       .toLowerCase()
  //       .includes(searchQuery.toLowerCase()) ||
  //     importingFirmName.toLowerCase().includes(searchQuery.toLowerCase())
  //   );
  // });

  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [hiddenIds, setHiddenIds] = useState(new Set());

  const perPage = 10;
  const totalPage = Math.ceil(shippingDetailsData.length / perPage);
  const end = page * perPage;
  const start = end - perPage;
  const reverseData = [...shippingDetailsData].slice(start, end);

  // Retrieve hidden IDs from localStorage when component loads
  useEffect(() => {
    const storedHiddenIds = JSON.parse(
      localStorage.getItem("hiddenIds") || "[]"
    );
    setHiddenIds(new Set(storedHiddenIds));
  }, []);

  // Store hidden IDs in localStorage whenever they change
  useEffect(() => {
    localStorage.setItem("hiddenIds", JSON.stringify([...hiddenIds]));
  }, [hiddenIds]);

  // Filter the visible data based on search query and hidden IDs
  const filteredData = reverseData.filter((el) => {
    const { id, salesContractNumber, shipmentWiseData, salesContract } = el;
    const exporterName = shipmentWiseData?.exporterName || "";
    const hssName = shipmentWiseData?.hssName || "";
    const status =
      shipmentWiseData?.remainingQuantity === "0" ? "Completed" : "Pending";
    const salesContractContactPersonName =
      salesContract?.salesContractContactPersonName || "";
    const importingFirmName = salesContract?.importingFirmName || "";

    // Exclude hidden items
    if (hiddenIds.has(id)) {
      return false;
    }

    return (
      salesContractNumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
      exporterName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      hssName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      status.toLowerCase().includes(searchQuery.toLowerCase()) ||
      salesContractContactPersonName
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      importingFirmName.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  // Hide the data by adding the ID to the hidden set
  const hideData = (_id) => {
    setHiddenIds((prev) => new Set(prev).add(_id));
  };

  // Show the hidden data by removing the ID from the hidden set
  const showData = (_id) => {
    setHiddenIds((prev) => {
      const newSet = new Set(prev);
      newSet.delete(_id);
      return newSet;
    });
  };

  return (
    <Box
      pl={{ base: "2", md: "10" }}
      pt={{ base: "10", md: "10" }}
      w={{ base: "100%", md: "80%" }}
    >
      <Navbar />
      <br />

      <Flex
        flexWrap="wrap"
        gap={3}
        mb={4}
        direction={{ base: "column", md: "row" }}
        justifyContent={{ base: "flex-start", md: "flex-start" }}
      >
        <Card w={300} p={15} bgGradient="linear(to-r, teal.100, green.200)">
          <CardBody>
            <Flex
              spacing="3"
              // textAlign="center"
              align="center"
              justify="start"
              direction={{ base: "column", md: "row" }}
            >
              <Box
                borderRadius="20px"
                p={2}
                bg="green.300"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <IoPieChart size={50} color="white" />
              </Box>
              <Text ml={5}>
                <Text fontSize="2xl" textAlign="start" fontWeight={"bold"}>
                  {paginationData.length}
                </Text>
                <Text fontSize="md" textAlign="start" fontWeight={"bold"}>
                  No of Sales Contract
                </Text>
              </Text>
            </Flex>
          </CardBody>
        </Card>
        <Card w={300} p={15} bgGradient="linear(to-r, #DCFCE7, #DCFCE7)">
          <CardBody>
            <Flex
              spacing="3"
              // textAlign="center"
              align="center"
              justify="start"
              direction={{ base: "column", md: "row" }}
            >
              <Box
                borderRadius="20px"
                p={2}
                bg="green.300"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <IoPieChart size={50} color="white" />
              </Box>
              <Text ml={5}>
                <Text fontSize="2xl" textAlign="start" fontWeight={"bold"}>
                  {shippingDetailsData.length}
                </Text>
                <Text fontSize="md" textAlign="start" fontWeight={"bold"}>
                  Upcoming Shipment
                </Text>
              </Text>
            </Flex>
          </CardBody>
        </Card>
        <Card w={320} p={15} bgGradient="linear(to-r, green.100, teal.200)">
          <CardBody>
            <Flex
              spacing="3"
              // textAlign="center"
              align="center"
              justify="start"
              direction={{ base: "column", md: "row" }}
            >
              <Box
                borderRadius="20px"
                p={2}
                bg="green.300"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <IoPieChart size={50} color="white" />
              </Box>
              <Text ml={5}>
                <Text fontSize="2xl" textAlign="start" fontWeight={"bold"}>
                  {fetchfinancerNamesList.length}
                </Text>
                <Text fontSize="md" textAlign="start" fontWeight={"bold"}>
                  No of Financer
                </Text>
              </Text>
            </Flex>
          </CardBody>
        </Card>
      </Flex>
      <Flex
        direction={{ base: "column", md: "row" }}
        justifyContent={{ base: "flex-start", md: "space-between" }}
      >
        <Heading textAlign={"start"} size={"md"} p={4}>
          Shipment Details
        </Heading>
        <Input
          placeholder="Search"
          w={300}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </Flex>
      <Flex
        direction={{ base: "column", md: "row" }}
        justifyContent={{ base: "flex-start", md: "space-between" }}
      >
        <Box
          w={{ base: "100%", md: "100%" }}
          p={{ base: "5", md: "5" }}
          //minH={750}
          borderRadius={10}
          boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"}
        >
          <TableContainer>
            <Table variant="simple" colorScheme="teal" boxShadow={"md"}>
              <Thead>
                <Tr boxShadow={"sm"}>
                  <Th
                    boxShadow={"sm"}
                    style={{ textAlign: "center", color: "teal" }}
                  >
                    Index
                  </Th>
                  <Th
                    boxShadow={"sm"}
                    style={{ textAlign: "center", color: "teal" }}
                  >
                    Sales Contract No
                  </Th>

                  <Th
                    boxShadow={"sm"}
                    style={{ textAlign: "center", color: "teal" }}
                  >
                    Exporter Name
                  </Th>
                  <Th
                    boxShadow={"sm"}
                    style={{ textAlign: "center", color: "teal" }}
                  >
                    Contact Person
                  </Th>
                  <Th
                    boxShadow={"sm"}
                    style={{ textAlign: "center", color: "teal" }}
                  >
                    Importing Firm Name
                  </Th>
                  <Th
                    boxShadow={"sm"}
                    style={{ textAlign: "center", color: "teal" }}
                  >
                    Type Of Duty
                  </Th>
                  <Th
                    boxShadow={"sm"}
                    style={{ textAlign: "center", color: "teal" }}
                  >
                    Type Of Import
                  </Th>
                  <Th
                    boxShadow={"sm"}
                    style={{ textAlign: "center", color: "teal" }}
                  >
                    Item Name
                  </Th>
                  <Th
                    boxShadow={"sm"}
                    style={{ textAlign: "center", color: "teal" }}
                  >
                    Quantity
                  </Th>
                  <Th
                    boxShadow={"sm"}
                    style={{ textAlign: "center", color: "teal" }}
                  >
                    Price
                  </Th>
                  <Th
                    boxShadow={"sm"}
                    style={{ textAlign: "center", color: "teal" }}
                  >
                    Rate
                  </Th>
                  <Th
                    boxShadow={"sm"}
                    style={{ textAlign: "center", color: "teal" }}
                  >
                    Status
                  </Th>
                  <Th
                    boxShadow={"sm"}
                    style={{ textAlign: "center", color: "teal" }}
                  >
                    Pending Qty
                  </Th>
                </Tr>
              </Thead>

              <Tbody>
                {filteredData.length > 0 &&
                  filteredData.map((el, index) => {
                    //if (hiddenIds.has(el._id)) return null;
                    const isHidden = hiddenIds.has(el._id);
                    const itemNames = el.rows
                      .map((row) => row.itemName)
                      .filter(Boolean);
                    const displayedItemNames =
                      itemNames.length > 2
                        ? `${itemNames.slice(0, 2).join(", ")}...`
                        : itemNames.join(", ");

                    const totalQuantityComma = el.rows
                      .map((row) => row.quantity)
                      .filter(
                        (quantity) =>
                          quantity !== undefined && quantity !== null
                      )
                      .join(", ");

                    const totalPriceComma = el.rows
                      .map((row) => row.rate)
                      .filter((rate) => rate !== undefined && rate !== null)
                      .join(", ");

                    return (
                      <Tr boxShadow={"md"} key={el._id}>
                        <Td
                          style={{
                            textAlign: "center",
                            opacity: isHidden ? 0.5 : 1,
                          }}
                        >
                          <Wrap>
                            <WrapItem>
                              <Flex justifyContent={"space-around"}>
                                {isHidden ? (
                                  <PiPlusCircleBold
                                    onClick={() => showData(el._id)}
                                    size={24}
                                    // style={{marginTop:2}}
                                  />
                                ) : (
                                  <PiMinusCircleBold
                                    onClick={() => hideData(el._id)}
                                    size={24}
                                    // style={{marginTop:2}}
                                  />
                                )}

                                <Heading size={"xs"}>
                                  <Button
                                    {...ButtonStyle}
                                    onClick={() =>
                                      isHidden
                                        ? showData(el._id)
                                        : hideData(el._id)
                                    }
                                  >
                                    {/* {index + 1} */}
                                    {el.salesIdentifier}
                                  </Button>
                                </Heading>
                              </Flex>
                            </WrapItem>
                          </Wrap>
                        </Td>
                        <Link to="/salescontract">
                          <Td
                            style={{
                              textAlign: "center",
                              opacity: isHidden ? 0.5 : 1,
                            }}
                          >
                            <Wrap>
                              <WrapItem>
                                <Flex justifyContent={"space-around"} gap={2}>
                                  <Heading size={"xs"}>
                                    <Button {...ButtonStyle}>
                                      {el.salesContractNumber}
                                    </Button>
                                  </Heading>
                                </Flex>
                              </WrapItem>
                            </Wrap>
                          </Td>
                        </Link>

                        <Td
                          style={{
                            textAlign: "center",
                            opacity: isHidden ? 0.5 : 1,
                          }}
                        >
                          <Button {...ButtonStyle}>
                            {el?.shipmentWiseData?.hssName
                              ? el?.shipmentWiseData?.hssName
                              : el?.shipmentWiseData?.exporterName}
                          </Button>
                        </Td>
                        <Td
                          style={{
                            textAlign: "center",
                            opacity: isHidden ? 0.5 : 1,
                          }}
                        >
                          <Button {...ButtonStyle}>
                            {el.salesContract
                              ? el.salesContract.salesContractContactPersonName
                              : ""}
                          </Button>
                        </Td>
                        <Td
                          style={{
                            textAlign: "center",
                            opacity: isHidden ? 0.5 : 1,
                          }}
                        >
                          <Button {...ButtonStyle}>
                            {el.salesContract
                              ? el.salesContract.importingFirmName
                              : ""}
                          </Button>
                        </Td>

                        <Td
                          style={{
                            textAlign: "center",
                            opacity: isHidden ? 0.5 : 1,
                          }}
                        >
                          <Button {...ButtonStyle}>
                            {el.salesContract
                              ? el.salesContract.typeOfDuty
                              : ""}
                          </Button>
                        </Td>
                        <Td
                          style={{
                            textAlign: "center",
                            opacity: isHidden ? 0.5 : 1,
                          }}
                        >
                          <Button {...ButtonStyle}>
                            {el.salesContract
                              ? el.salesContract.typeOfImport
                              : ""}
                          </Button>
                        </Td>

                        <Td
                          style={{
                            textAlign: "center",
                            opacity: isHidden ? 0.5 : 1,
                          }}
                        >
                          <Popover>
                            <PopoverTrigger>
                              <Button {...ButtonStyle}>
                                {displayedItemNames}
                              </Button>
                            </PopoverTrigger>
                            <PopoverContent>
                              <PopoverArrow />
                              <PopoverCloseButton />
                              <PopoverHeader>Item Names</PopoverHeader>
                              <PopoverBody>
                                <Text>{itemNames.join(", ")}</Text>
                              </PopoverBody>
                            </PopoverContent>
                          </Popover>
                        </Td>
                        <Td
                          style={{
                            textAlign: "center",
                            opacity: isHidden ? 0.5 : 1,
                          }}
                        >
                          <Button {...ButtonStyle}>{totalQuantityComma}</Button>
                        </Td>
                        <Td
                          style={{
                            textAlign: "center",
                            opacity: isHidden ? 0.5 : 1,
                          }}
                        >
                          <Button {...ButtonStyle}>{totalPriceComma}</Button>
                        </Td>
                        <Td
                          style={{
                            textAlign: "center",
                            opacity: isHidden ? 0.5 : 1,
                          }}
                        >
                          <Button {...ButtonStyle}>
                            {el.rows[0]?.invoiceValue}
                          </Button>
                        </Td>
                        <Td
                          style={{
                            textAlign: "center",
                            opacity: isHidden ? 0.5 : 1,
                          }}
                        >
                          {/* <Button
                            {...ButtonStyle}
                            bg={
                              el.shipmentWiseData.remainingQuantity === "0"
                                ? "green"
                                : "blue.100"
                            }
                            color={
                              el.shipmentWiseData.remainingQuantity === "0"
                                ? "white"
                                : "black"
                            }
                            _hover={{
                              backgroundColor: "green.200",
                              color: "black",
                            }}
                          >
                            {el.shipmentWiseData.remainingQuantity === "0"
                              ? "Completed"
                              : "Pending"}
                          </Button> */}
                          <Button
                            {...ButtonStyle}
                            bg={
                              el?.shipmentWiseData?.remainingQuantity === "0"
                                ? "green"
                                : "blue.100"
                            }
                            color={
                              el?.shipmentWiseData?.remainingQuantity === "0"
                                ? "white"
                                : "black"
                            }
                            _hover={{
                              backgroundColor: "green.200",
                              color: "black",
                            }}
                          
                          >
                            {el?.status ? el?.status : ""}
                          </Button>
                        </Td>
                        <Td
                          style={{
                            textAlign: "center",
                            opacity: isHidden ? 0.5 : 1,
                          }}
                        >
                          <Button {...ButtonStyle}>
                            {el.shipmentWiseData.remainingQuantity
                              ? el.shipmentWiseData.remainingQuantity
                              : ""}
                          </Button>
                        </Td>
                      </Tr>
                    );
                  })}
              </Tbody>
            </Table>
            <Box>
              {filteredData.length === 0 && (
                <Alert
                  status="info"
                  variant="subtle"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  mt={4}
                >
                  <AlertIcon boxSize={8} />
                  No Record Found
                </Alert>
              )}
            </Box>
          </TableContainer>
          <Flex justifyContent={"flex-end"} mt={5}>
            <Pagination page={page} setPage={setPage} totalPage={totalPage} />
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

export default Dashboard;
