import React, { useState, useRef, useEffect } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Box,
  FormControl,
  FormLabel,
  Flex,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  TableContainer,
  Text,
  Stack,
  Grid,
  GridItem,
  Select,
} from "@chakra-ui/react";
import SelectComponent from "react-select";
import { CSVLink } from "react-csv";
import { useReactToPrint } from "react-to-print";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./datepicker-custom.css";
import { useAuth } from "../../../Context/AuthProvider";
import Navbar from "../../../Components/Navbar";
import { CloseIcon } from "@chakra-ui/icons";
import ReportsData from "./ReportsData";
import { Bar, Pie, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);
const ReportsTable = () => {
  const [filters, setFilters] = useState({
    countryOfOrigin: null,
    exporterName: null,
    clearingAgent: null,
    shippingLine: null,
    typeOfDuty: null,
    bankName: null,
    eta: null,
  });

  const { shippingDetailsData, fetchfinanceGenerationNamesList,fetchrePaymentList } = useAuth();

  const [tableData, setTableData] = useState(shippingDetailsData);
  //console.log("tableData", tableData);
  const tableRef = useRef();
  const [financer, setFinancer] = useState("");
  const [chartFinancerData, setChartFinancerData] = useState(null);
  const [visibleColumns, setVisibleColumns] = useState([]);
  const [visibleSummaryColumns, setVisibleSummaryColumns] = useState([]);

  const generateColors = (count) => {
    const colors = [];
    for (let i = 0; i < count; i++) {
      const r = Math.floor(Math.random() * 255);
      const g = Math.floor(Math.random() * 255);
      const b = Math.floor(Math.random() * 255);
      colors.push(`rgba(${r}, ${g}, ${b}, 0.6)`); // Background color with transparency
    }
    return colors;
  };
  const transformFinancerData = (chartType, financer) => {
    // Filter data based on selected financer
    const filteredData = financer
      ? fetchfinanceGenerationNamesList.filter(
          (data) => data.financerOrganizationName === financer
        )
      : fetchfinanceGenerationNamesList;

    // Extract labels (exporter names) and values (invoice values)
    const labels = filteredData.map((data) => data.exporterName);
    const values = filteredData.map((data) =>
      parseFloat(data.exporterInvoiceValue || 0)
    );

    const count = labels.length; // Number of data points
    const backgroundColors = generateColors(count); // Dynamic background colors
    const borderColors = backgroundColors.map(
      (color) => color.replace("0.6", "1") // Convert to opaque for borders
    );

    if (chartType === "pie") {
      // For Pie Chart: Combine Exporter Name and Invoice Value
      const pieLabels = labels.map(
        (label, index) => `${label} (Invoice: ${values[index].toFixed(2)})`
      );

      return {
        labels: pieLabels,
        datasets: [
          {
            label: "Exporter Invoice Value",
            data: values,
            backgroundColor: backgroundColors,
            borderColor: borderColors,
            borderWidth: 1,
          },
        ],
      };
    } else {
      // For Bar and Line Charts: Show Total Invoice Value
      return {
        labels,
        datasets: [
          {
            label: "Exporter Invoice Value",
            data: values,
            backgroundColor: backgroundColors,
            borderColor: borderColors,
            borderWidth: 1,
          },
        ],
      };
    }
  };

  useEffect(() => {
    const chartData = transformFinancerData("pie", financer);
    setChartFinancerData(chartData);
  }, [financer, fetchfinanceGenerationNamesList]);

  const transformShippingData = () => {
    const labels = shippingDetailsData.flatMap((shipment) =>
      shipment.rows.map((row) => row.itemName)
    );

    const values = shippingDetailsData.flatMap((shipment) =>
      shipment.rows.map((row) => parseFloat(row.quantity || 0))
    );

    const rates = shippingDetailsData.flatMap((shipment) =>
      shipment.rows.map((row) => parseFloat(row.rate || 0))
    );

    const invoiceValues = shippingDetailsData.flatMap((shipment) =>
      shipment.rows.map((row) => parseFloat(row.invoiceValueExporter || 0))
    );

    const count = labels.length; // Number of data points
    const backgroundColors = generateColors(count); // Dynamic background colors
    const borderColors = backgroundColors.map(
      (color) => color.replace("0.6", "1") // Convert to opaque for borders
    );

    // Enhanced labels with details for better display
    const pieLabels = labels.map(
      (label, index) =>
        `${label} (Qty: ${values[index]},Value: ${invoiceValues[index]}), Rate: ${rates[index]}`
    );

    return {
      labels: pieLabels,
      datasets: [
        {
          label: "Shipping Quantity",
          data: values,
          backgroundColor: backgroundColors,
          borderColor: borderColors,
          borderWidth: 1,
        },
      ],
    };
  };

  const columnConfigurations = {
    shipmentcountry: [
      "exporterName",
      "typeOfDuty",
      "typeOfImport",
      "itemName",
      "invoiceQty",
      "rate",
      "exportInvoiceValue",
      "actualInvoiceValue",
      "billOfLadingNo",
      "billOfEntry",
      "billOfEntryDate",
    ],
    exporterName: [
      "shipmentcountry",
      "typeOfDuty",
      "typeOfImport",
      "itemName",
      "invoiceQty",
      "rate",
      "exportInvoiceValue",
      "actualInvoiceValue",
      "billOfLadingNo",
      "billOfEntry",
      "billOfEntryDate",
      "averageWeightIV",
    ],
    clearingHouseAgentName: [
      "exporterName",
      "typeOfDuty",
      "typeOfImport",
      "itemName",
      "invoiceQty",
      "shipmentcountry",
      "shippingLine",
      "billOfEntry",
      "billOfLadingNo",
      "billOfEntryDate",
    ],
    shippingLine: [
      "exporterName",
      "typeOfDuty",
      "typeOfImport",
      "itemName",
      "invoiceQty",
      "shipmentcountry",
      "chaName",
      "billOfEntry",
      "billOfLadingNo",
      "billOfEntryDate",
    ],
    typeOfDuty: [
      "exporterName",
      "typeOfImport",
      "itemName",
      "invoiceQty",
      "rate",
      "shipmentcountry",
      "exportInvoiceValue",
      "actualInvoiceValue",
      "billOfLadingNo",
      "billOfEntry",
      "billOfEntryDate",
    ],
    paymentBankName: [
      "exporterName",
      "typeOfDuty",
      "typeOfImport",
      "itemName",
      "invoiceQty",
      "rate",
      "exportInvoiceValue",
      "paymentDate",
      "billOfLadingNo",
      "transactionReferenceNo",
      "amount",
      "exchangeRate",
      "valueInINR",
      "billOfEntry",
      "billOfEntryDate",
    ],
    eta: [
      "exporterName",
      "typeOfDuty",
      "typeOfImport",
      "itemName",
      "invoiceQty",
      "exportInvoiceValue",
      "clearingHouseAgentName",
      "billOfEntry",
      "shipmentcountry",
      "finalDeliveryPort",
      "eta",
    ],
  };

  const columnSummaryConfigurations = {
    shipmentcountry: [
      "exporterName",
      "typeOfDuty",
      "itemName",
      "invoiceQty",
      "exportInvoiceValue",
      "actualInvoiceValue",
    ],
    exporterName: [
      "shipmentcountry",
      "typeOfDuty",
      "itemName",
      "invoiceQty",
      "exportInvoiceValue",
      "actualInvoiceValue",
    ],
    clearingHouseAgentName: [
      "exporterName",
      "typeOfImport",
      "itemName",
      "invoiceQty",
    ],
    shippingLine: ["exporterName", "shipmentcountry", "invoiceQty"],
    typeOfDuty: [
      "exporterName",
      "itemName",
      "shipmentcountry",
      "invoiceQty",
      "exportInvoiceValue",
    ],
    paymentBankName: [
      "exporterName",
      "itemName",
      "invoiceQty",
      "exportInvoiceValue",
      "amount",
      "valueInINR",
    ],
    eta: [
      "exporterName",
      "typeOfDuty",
      "typeOfImport",
      "itemName",
      "invoiceQty",
      "exportInvoiceValue",
      "clearingHouseAgentName",
      "billOfEntry",
      "shipmentcountry",
      "finalDeliveryPort",
      "eta",
    ],
  };

  const handleFilterChange = (field, value) => {
    setFilters((prev) => ({ ...prev, [field]: value }));
    applyFilters({ ...filters, [field]: value });
    setVisibleColumns(columnConfigurations[field] || []);
    setVisibleSummaryColumns(columnSummaryConfigurations[field] || []);
  };

  const clearFilters = () => {
    const defaultFilters = {
      countryOfOrigin: null,
      exporterName: null,
      clearingAgent: null,
      shippingLine: null,
      typeOfDuty: null,
      bankName: null,
      eta: null,
    };

    setFilters(defaultFilters);
    setVisibleColumns([]);
    setVisibleSummaryColumns([]);
    applyFilters(defaultFilters);
  };

  const applyFilters = (updatedFilters) => {
    let filteredData = shippingDetailsData;

    // Apply each filter
    Object.keys(updatedFilters).forEach((key) => {
      if (updatedFilters[key]) {
        if (key === "eta") {
          // Filter for ETA by comparing if the item.eta is after the selected date
          filteredData = filteredData.filter((item) => {
            const itemEta = new Date(item.shipmentWiseData.eta);
            const selectedEta = new Date(updatedFilters[key]);
  
            // Only include items with ETA after the selected date
            return itemEta >= selectedEta;
          });
        } else if (key === "paymentBankName") {
          // Filter for paymentBankName within the paymentStatusRows array
          filteredData = filteredData.filter((item) =>
            item.paymentStatusRows.some((row) =>
              row.paymentBankName
                ?.toLowerCase()
                .includes(updatedFilters[key]?.toLowerCase())
            )
          );
        } else {
          // General filter for shipmentWiseData keys
          filteredData = filteredData.filter((item) =>
            item.shipmentWiseData[key]
              ?.toLowerCase()
              .includes(updatedFilters[key]?.toLowerCase())
          );
        }
      }
    });

    setTableData(filteredData);
  };

  // const printTable = useReactToPrint({
  //   content: () => tableRef.current,
  // });

  const columnLabels = {
    shipmentcountry: "Country of Origin",
    exporterName: "Exporter Name",
    typeOfDuty: "Type of Duty",
    typeOfImport: "Type of Import",
    itemName: "Item Name",
    invoiceQty: "Invoice Qty",
    rate: "Rate",
    exportInvoiceValue: "Export Invoice Value",
    actualInvoiceValue: "Actual Invoice Value",
    billOfLadingNo: "Bill of Lading No",
    billOfEntry: "Bill of Entry",
    billOfEntryDate: "Bill of Entry Date",
    averageWeightIV: "Average Weight-IV Total in Level III",
    chaName: "CHA Name",
    shippingLine: "Shipping Line",
    paymentDate: "Payment Date",
    transactionReferenceNo: "Transaction Reference No",
    amount: "Amount",
    exchangeRate: "Exchange Rate",
    valueInINR: "Value in INR",
    blNo: "BL No",
    finalDeliveryPort: "Final Delivery Port",
    eta: "Estimated Time of Arrival",
    clearingHouseAgentName: "Clearing Agent",
  };

  // const renderCellData1 = (column, row) => {
  //   switch (column) {
  //     case "shipmentcountry":
  //       return (
  //         <Box>
  //           <Text>{row.shipmentWiseData.shipmentcountry}</Text>
  //         </Box>
  //       );

  //     case "exporterName":
  //       return (
  //         <Box>
  //           <Text>{row.shipmentWiseData.exporterName}</Text>
  //         </Box>
  //       );

  //     case "typeOfDuty":
  //       return (
  //         <Box>
  //           <Text>{row.shipmentWiseData.typeOfDuty}</Text>
  //         </Box>
  //       );

  //     case "typeOfImport":
  //       return (
  //         <Box>
  //           <Text>{row.salesContract.typeOfImport}</Text>
  //         </Box>
  //       );

  //     case "itemName":
  //       return row.rows.map((item, index) => (
  //         <Box key={item._id}>
  //           <Text>
  //             {item.itemName}
  //             {index < row.rows.length - 1 ? ", " : ""}
  //           </Text>
  //         </Box>
  //       ));

  //     case "invoiceQty":
  //       return row.rows.map((item, index) => (
  //         <Box key={item._id}>
  //           <Text>
  //             {item.quantity}
  //             {index < row.rows.length - 1 ? ", " : ""}
  //           </Text>
  //         </Box>
  //       ));
  //     case "rate":
  //       return row.rows.map((item, index) => (
  //         <Box key={item._id}>
  //           <Text>
  //             {item.rate}
  //             {index < row.rows.length - 1 ? ", " : ""}
  //           </Text>
  //         </Box>
  //       ));
  //     case "exportInvoiceValue":
  //       return row.rows.map((item, index) => (
  //         <Box key={item._id}>
  //           <Text>
  //             {item.invoiceValueExporter}
  //             {index < row.rows.length - 1 ? ", " : ""}
  //           </Text>
  //         </Box>
  //       ));

  //     case "actualInvoiceValue":
  //       return row.rows.map((item, index) => (
  //         <Box key={item._id}>
  //           <Text>
  //             {item.invoiceValue}
  //             {index < row.rows.length - 1 ? ", " : ""}
  //           </Text>
  //         </Box>
  //       ));
  //     case "billOfLadingNo":
  //       return (
  //         <Box>
  //           <Text>{row.shipmentWiseData.lendingNo}</Text>
  //         </Box>
  //       );
  //     case "billOfEntry":
  //       return (
  //         <Box>
  //           <Text>{row.customerClearingStatusData.beNo}</Text>
  //         </Box>
  //       );
  //     case "billOfEntryDate":
  //       return (
  //         <Box>
  //           <Text>
  //             {new Date(row.customerClearingStatusData.beDate)
  //               .toISOString()
  //               .slice(0, 10)
  //               .split("-")
  //               .reverse()
  //               .join("-")}
  //           </Text>
  //         </Box>
  //       );
  //     case "averageWeightIV":
  //       return row.totalsWeightLevel3.map((item, index) => (
  //         <Box key={item._id}>
  //           <Text>
  //             {item.averageWeight4}
  //             {index < row.totalsWeightLevel3.length - 1 ? ", " : ""}
  //           </Text>
  //         </Box>
  //       ));
  //     case "shippingLine":
  //       return (
  //         <Box>
  //           <Text>{row.shipmentWiseData.shippingLine}</Text>
  //         </Box>
  //       );
  //     case "chaName":
  //       return row.rowsDeliver.map((item, index) => (
  //         <Box key={item._id}>
  //           <Text>
  //             {item.chaName}
  //             {index < row.rowsDeliver.length - 1 ? ", " : ""}
  //           </Text>
  //         </Box>
  //       ));
  //     case "clearingHouseAgentName":
  //       return (
  //         <Box>
  //           <Text>{row.shipmentWiseData.clearingHouseAgentName}</Text>
  //         </Box>
  //       );
  //     case "finalDeliveryPort":
  //       return (
  //         <Box>
  //           <Text>{row.shipmentWiseData.finalDeliveryPort}</Text>
  //         </Box>
  //       );
  //     case "eta":
  //       return (
  //         <Box>
  //           <Text>
  //             {new Date(row.shipmentWiseData.eta)
  //               .toISOString()
  //               .slice(0, 10)
  //               .split("-")
  //               .reverse()
  //               .join("-")}
  //           </Text>
  //         </Box>
  //       );
  //     case "paymentDate":
  //       return row.paymentStatusRows.map((item, index) => (
  //         <Box key={item._id}>
  //           <Text>
  //             {new Date(item.paymentDate)
  //               .toISOString()
  //               .slice(0, 10)
  //               .split("-")
  //               .reverse()
  //               .join("-")}
  //             {index < row.paymentStatusRows.length - 1 ? ", " : ""}
  //           </Text>
  //         </Box>
  //       ));
  //     case "transactionReferenceNo":
  //       return row.paymentStatusRows.map((item, index) => (
  //         <Box key={item._id}>
  //           <Text>
  //             {item.transactionRefNo}
  //             {index < row.paymentStatusRows.length - 1 ? ", " : ""}
  //           </Text>
  //         </Box>
  //       ));
  //     case "amount":
  //       return row.paymentStatusRows.map((item, index) => (
  //         <Box key={item._id}>
  //           <Text>
  //             {item.paymentAmount}
  //             {index < row.paymentStatusRows.length - 1 ? ", " : ""}
  //           </Text>
  //         </Box>
  //       ));
  //     case "exchangeRate":
  //       return row.paymentStatusRows.map((item, index) => (
  //         <Box key={item._id}>
  //           <Text>
  //             {item.exchangeRate}
  //             {index < row.paymentStatusRows.length - 1 ? ", " : ""}
  //           </Text>
  //         </Box>
  //       ));
  //     case "valueInINR":
  //       return row.paymentStatusRows.map((item, index) => (
  //         <Box key={item._id}>
  //           <Text>
  //             {item.amountInINR}
  //             {index < row.paymentStatusRows.length - 1 ? ", " : ""}
  //           </Text>
  //         </Box>
  //       ));

  //     default:
  //       return "-";
  //   }
  // };

  const renderCellData = (column, row) => {
    switch (column) {
      case "shipmentcountry":
        return (
          <Box>
            <Text>{row.shipmentWiseData.shipmentcountry}</Text>
          </Box>
        );

      case "exporterName":
        return (
          <Box>
            <Text>{row.shipmentWiseData.exporterName}</Text>
          </Box>
        );

      case "typeOfDuty":
        return (
          <Box>
            <Text>{row.shipmentWiseData.typeOfDuty}</Text>
          </Box>
        );

      case "typeOfImport":
        return (
          <Box>
            <Text>{row.salesContract.typeOfImport}</Text>
          </Box>
        );

      case "itemName":
        return (
          <Stack key={row.id} spacing={2}>
            {row.rows.map((item) => (
              <Box key={item._id}>
                <Text>{item.itemName}</Text>
              </Box>
            ))}
          </Stack>
        );

      case "invoiceQty":
        return (
          <Stack key={row.id} spacing={2}>
            {row.rows.map((item) => (
              <Box key={item._id}>
                <Text>{item.quantity}</Text>
              </Box>
            ))}
          </Stack>
        );

      case "rate":
        return (
          <Stack key={row.id} spacing={2}>
            {row.rows.map((item) => (
              <Box key={item._id}>
                <Text>{item.rate}</Text>
              </Box>
            ))}
          </Stack>
        );

      case "exportInvoiceValue":
        return (
          <Stack key={row.id} spacing={2}>
            {row.rows.map((item) => (
              <Box key={item._id}>
                <Text>{item.invoiceValueExporter}</Text>
              </Box>
            ))}
          </Stack>
        );

      case "actualInvoiceValue":
        return (
          <Stack key={row.id} spacing={2}>
            {row.rows.map((item) => (
              <Box key={item._id}>
                <Text>{item.invoiceValue}</Text>
              </Box>
            ))}
          </Stack>
        );
      case "billOfLadingNo":
        return (
          <Box>
            <Text>{row.shipmentWiseData.lendingNo}</Text>
          </Box>
        );
      case "billOfEntry":
        return (
          <Box>
            <Text>{row.customerClearingStatusData.beNo}</Text>
          </Box>
        );
      case "billOfEntryDate":
        return (
          <Box>
            <Text>
              {new Date(row.customerClearingStatusData.beDate)
                .toISOString()
                .slice(0, 10)
                .split("-")
                .reverse()
                .join("-")}
            </Text>
          </Box>
        );

      case "averageWeightIV":
        return (
          <Stack key={row.id} spacing={2}>
            {row.totalsWeightLevel3.map((item) => (
              <Box key={item._id}>
                <Text>{item.averageWeight4}</Text>
              </Box>
            ))}
          </Stack>
        );

      case "shippingLine":
        return (
          <Box>
            <Text>{row.shipmentWiseData.shippingLine}</Text>
          </Box>
        );

      case "chaName":
        return (
          <Stack key={row.id} spacing={2}>
            {row.rowsDeliver.map((item) => (
              <Box key={item._id}>
                <Text>{item.chaName}</Text>
              </Box>
            ))}
          </Stack>
        );
      case "clearingHouseAgentName":
        return (
          <Box>
            <Text>{row.shipmentWiseData.clearingHouseAgentName}</Text>
          </Box>
        );
      case "finalDeliveryPort":
        return (
          <Box>
            <Text>{row.shipmentWiseData.finalDeliveryPort}</Text>
          </Box>
        );
      case "eta":
        return (
          <Box>
            <Text>
              {new Date(row.shipmentWiseData.eta)
                .toISOString()
                .slice(0, 10)
                .split("-")
                .reverse()
                .join("-")}
            </Text>
          </Box>
        );

      case "paymentDate":
        return (
          <Stack key={row.id} spacing={2}>
            {row.paymentStatusRows.map((item) => (
              <Box key={item._id}>
                <Text>
                  {new Date(item.paymentDate)
                    .toISOString()
                    .slice(0, 10)
                    .split("-")
                    .reverse()
                    .join("-")}
                </Text>
              </Box>
            ))}
          </Stack>
        );

      case "transactionReferenceNo":
        return (
          <Stack key={row.id} spacing={2}>
            {row.paymentStatusRows.map((item) => (
              <Box key={item._id}>
                <Text>{item.transactionRefNo}</Text>
              </Box>
            ))}
          </Stack>
        );

      case "amount":
        return (
          <Stack key={row.id} spacing={2}>
            {row.paymentStatusRows.map((item) => (
              <Box key={item._id}>
                <Text>{item.paymentAmount}</Text>
              </Box>
            ))}
          </Stack>
        );

      case "exchangeRate":
        return (
          <Stack key={row.id} spacing={2}>
            {row.paymentStatusRows.map((item) => (
              <Box key={item._id}>
                <Text>{item.exchangeRate}</Text>
              </Box>
            ))}
          </Stack>
        );

      case "valueInINR":
        return (
          <Stack key={row.id} spacing={2}>
            {row.paymentStatusRows.map((item) => (
              <Box key={item._id}>
                <Text>{item.amountInINR}</Text>
              </Box>
            ))}
          </Stack>
        );

      default:
        return "-";
    }
  };

  const prepareCsvData = () => {
    return tableData.map((row) => {
      const exportRow = {};
      visibleColumns.forEach((column) => {
        exportRow[columnLabels[column]] = renderCellExportData(column, row);
      });
      return exportRow;
    });
  };

  // Function to handle data formatting for export
  const renderCellExportData = (column, row) => {
    switch (column) {
      case "shipmentcountry":
        return row.shipmentWiseData.shipmentcountry;
      case "exporterName":
        return row.shipmentWiseData.exporterName;
      case "typeOfDuty":
        return row.shipmentWiseData.typeOfDuty;
      case "typeOfImport":
        return row.salesContract.typeOfImport;
      case "itemName":
        return row.rows.map((item) => item.itemName).join("\n");
      case "invoiceQty":
        return row.rows.map((item) => item.quantity).join("\n");
      case "rate":
        return row.rows.map((item) => item.rate).join("\n");
      case "exportInvoiceValue":
        return row.rows.map((item) => item.invoiceValueExporter).join("\n");
      case "actualInvoiceValue":
        return row.rows.map((item) => item.invoiceValue).join("\n");
      case "billOfLadingNo":
        return row.shipmentWiseData.lendingNo;
      case "billOfEntry":
        return row.customerClearingStatusData.beNo;
      case "billOfEntryDate":
        return new Date(row.customerClearingStatusData.beDate)
          .toISOString()
          .slice(0, 10)
          .split("-")
          .reverse()
          .join("-");
      case "averageWeightIV":
        return row.totalsWeightLevel3
          .map((item) => item.averageWeight4)
          .join("\n");
      case "shippingLine":
        return row.shipmentWiseData.shippingLine;
      case "chaName":
        return row.rowsDeliver.map((item) => item.chaName).join("\n");
      case "clearingHouseAgentName":
        return row.shipmentWiseData.clearingHouseAgentName;
      case "finalDeliveryPort":
        return row.shipmentWiseData.finalDeliveryPort;
      case "eta":
        return new Date(row.shipmentWiseData.eta)
          .toISOString()
          .slice(0, 10)
          .split("-")
          .reverse()
          .join("-");
      case "paymentDate":
        return row.paymentStatusRows
          .map((item) =>
            new Date(item.paymentDate)
              .toISOString()
              .slice(0, 10)
              .split("-")
              .reverse()
              .join("-")
          )
          .join("\n");
      case "transactionReferenceNo":
        return row.paymentStatusRows
          .map((item) => item.transactionRefNo)
          .join("\n");
      case "amount":
        return row.paymentStatusRows
          .map((item) => item.paymentAmount)
          .join("\n");
      case "exchangeRate":
        return row.paymentStatusRows
          .map((item) => item.exchangeRate)
          .join("\n");
      case "valueInINR":
        return row.paymentStatusRows.map((item) => item.amountInINR).join("\n");
      default:
        return renderCellData(column, row);
    }
  };

  const csvData = prepareCsvData();

  const prepareCsvSummaryData = () => {
    return tableData.map((row) => {
      const exportRow = {};
      visibleSummaryColumns.forEach((column) => {
        exportRow[columnLabels[column]] = renderCellExportSummaryData(
          column,
          row
        );
      });
      return exportRow;
    });
  };

  // Function to handle data formatting for export
  const renderCellExportSummaryData = (column, row) => {
    switch (column) {
      case "shipmentcountry":
        return row.shipmentWiseData.shipmentcountry;
      case "exporterName":
        return row.shipmentWiseData.exporterName;
      case "typeOfDuty":
        return row.shipmentWiseData.typeOfDuty;
      case "typeOfImport":
        return row.salesContract.typeOfImport;
      case "itemName":
        return row.rows.map((item) => item.itemName).join("\n");
      case "invoiceQty":
        return row.rows.map((item) => item.quantity).join("\n");
      case "rate":
        return row.rows.map((item) => item.rate).join("\n");
      case "exportInvoiceValue":
        return row.rows.map((item) => item.invoiceValueExporter).join("\n");
      case "actualInvoiceValue":
        return row.rows.map((item) => item.invoiceValue).join("\n");
      case "billOfLadingNo":
        return row.shipmentWiseData.lendingNo;
      case "billOfEntry":
        return row.customerClearingStatusData.beNo;
      case "billOfEntryDate":
        return new Date(row.customerClearingStatusData.beDate)
          .toISOString()
          .slice(0, 10)
          .split("-")
          .reverse()
          .join("-");
      case "averageWeightIV":
        return row.totalsWeightLevel3
          .map((item) => item.averageWeight4)
          .join("\n");
      case "shippingLine":
        return row.shipmentWiseData.shippingLine;
      case "chaName":
        return row.rowsDeliver.map((item) => item.chaName).join("\n");
      case "clearingHouseAgentName":
        return row.shipmentWiseData.clearingHouseAgentName;
      case "finalDeliveryPort":
        return row.shipmentWiseData.finalDeliveryPort;
      case "eta":
        return new Date(row.shipmentWiseData.eta)
          .toISOString()
          .slice(0, 10)
          .split("-")
          .reverse()
          .join("-");
      case "paymentDate":
        return row.paymentStatusRows
          .map((item) =>
            new Date(item.paymentDate)
              .toISOString()
              .slice(0, 10)
              .split("-")
              .reverse()
              .join("-")
          )
          .join("\n");
      case "transactionReferenceNo":
        return row.paymentStatusRows
          .map((item) => item.transactionRefNo)
          .join("\n");
      case "amount":
        return row.paymentStatusRows
          .map((item) => item.paymentAmount)
          .join("\n");
      case "exchangeRate":
        return row.paymentStatusRows
          .map((item) => item.exchangeRate)
          .join("\n");
      case "valueInINR":
        return row.paymentStatusRows.map((item) => item.amountInINR).join("\n");
      default:
        return renderCellData(column, row);
    }
  };

  const csvSummaryData = prepareCsvSummaryData();

  const renderDetailReport = () => (
    <Box ref={tableRef}>
      <Box mb={4} display="flex" justifyContent="flex-end">
        <CSVLink data={csvData} filename="detail_report.csv">
          <Button colorScheme="blue">Export to Excel</Button>
        </CSVLink>
        {/* <Button onClick={printTable} colorScheme="green" ml={5}>
          Print to PDF
        </Button> */}
      </Box>
      <TableContainer>
        <Table variant="striped" colorScheme="teal">
          <Thead>
            <Tr>
              {visibleColumns.map((column) => (
                <Th key={column}>{columnLabels[column]}</Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {tableData.map((row, index) => (
              <Tr key={index}>
                {visibleColumns.map((column) => (
                  <Td key={column}>{renderCellData(column, row)}</Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );

  const renderSummaryReport = () => (
    <Box ref={tableRef}>
      <Box mb={4} display="flex" justifyContent="flex-end">
        <CSVLink data={csvSummaryData} filename="detail_report.csv">
          <Button colorScheme="blue">Export to Excel</Button>
        </CSVLink>
        {/* <Button onClick={printTable} colorScheme="green" ml={5}>
          Print to PDF
        </Button> */}
      </Box>
      <TableContainer>
        <Table variant="striped" colorScheme="teal">
          <Thead>
            <Tr>
              {visibleSummaryColumns.map((column) => (
                <Th key={column}>{columnLabels[column]}</Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {tableData.map((row, index) => (
              <Tr key={index}>
                {visibleSummaryColumns.map((column) => (
                  <Td key={column}>{renderCellData(column, row)}</Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );

  const [selectedFinancer, setSelectedFinancer] = useState("");

  // const calculateRepaymentDate = (creditTakenDate, creditPeriod) => {
  //   const date = new Date(creditTakenDate);
  //   date.setDate(date.getDate() + parseInt(creditPeriod, 10));
  //   return date.toISOString().split("T")[0]; // Format as YYYY-MM-DD
  // };

  const calculateRepaymentDate = (creditTakenDate, creditPeriod) => {
    // Check if creditTakenDate is valid
    const date = new Date(creditTakenDate);
    if (isNaN(date)) {
      console.error("Invalid creditTakenDate:", creditTakenDate);
      return "Invalid Date";
    }
  
    // Parse creditPeriod and validate
    const period = parseInt(creditPeriod, 10);
    if (isNaN(period)) {
      console.error("Invalid creditPeriod:", creditPeriod);
      return "Invalid Period";
    }
  
    // Calculate repayment date
    date.setDate(date.getDate() + period);
    return date.toISOString().split("T")[0]; // Format as YYYY-MM-DD
  };
  

  // Filtered data based on selected financer
  const filteredData = fetchfinanceGenerationNamesList.filter(
    (data) => data.financerOrganizationName === selectedFinancer
  );

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date)) {
      console.error("Invalid date:", dateString);
      return "Invalid Date";
    }
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const tableFinancerData = filteredData.map((data) => ({
    transactionRefNo: data.transactionRefNo || "N/A",
    salesContractNumber: data.salesContractNumber,
    exporterName: data.exporterName,
    exporterInvoiceNo: data.exporterInvoiceNo,
    invoiceDate: formatDate(data.invoiceDate),
    exporterInvoiceValue: data.exporterInvoiceValue,
    creditTakenDate: formatDate(data.creditTakenDate),
    creditPeriod: data.creditPeriod,
    creditDueDate: formatDate(data.creditDueDate),
    repaymentDate: formatDate(calculateRepaymentDate(data.creditTakenDate, data.creditPeriod)),
  }));

  const newtableData = filteredData.map((financeData) => {
    // Find matching repayment data
    const matchingRepayment = fetchrePaymentList.find(
      (repayment) =>
        repayment.financerOrganizationName === financeData.financerOrganizationName &&
        repayment.transactionRefNo === financeData.transactionRefNo
    );

    // Extract amountInINR or set to "N/A" if no match is found
    const amountInINR = matchingRepayment ? matchingRepayment.amountInINR : "N/A";
console.log("amount",amountInINR)
    // Return enhanced data for the table
    return {
      transactionRefNo: financeData.transactionRefNo || "N/A",
      salesContractNumber: financeData.salesContractNumber,
      exporterName: financeData.exporterName,
      exporterInvoiceNo: financeData.exporterInvoiceNo,
      invoiceDate: financeData.invoiceDate,
      exporterInvoiceValue: financeData.exporterInvoiceValue,
      creditTakenDate: financeData.creditTakenDate,
      creditPeriod: financeData.creditPeriod,
      creditDueDate: financeData.creditDueDate,
      repaymentDate: formatDate(calculateRepaymentDate(financeData.creditTakenDate, financeData.creditPeriod)),
      amountInINR,
    };
  });
console.log("newtableData",newtableData)
  // Define headers with capitalized names
  const headers = [
    { label: "Transaction Ref No", key: "transactionRefNo" },
    { label: "Sales Contract No", key: "salesContractNumber" },
    { label: "Exporter Name", key: "exporterName" },
    { label: "Invoice No", key: "exporterInvoiceNo" },
    { label: "Invoice Date", key: "invoiceDate" },
    { label: "Invoice Value", key: "exporterInvoiceValue" },
    { label: "Credit Taken Date", key: "creditTakenDate" },
    { label: "Credit Period", key: "creditPeriod" },
    { label: "Credit Due Date", key: "creditDueDate" },
    { label: "Repayment Date", key: "repaymentDate" },
    { label: "Repayment Amount", key: "amountInINR" },
  ];

  const renderFinancerReport = () => (
    
    <Box ref={tableRef}>
      <Box mb={4} display="flex" justifyContent="flex-end">
        <CSVLink data={newtableData} headers={headers} filename="finance_report.csv">
          <Button colorScheme="blue">Export to Excel</Button>
        </CSVLink>
        {/* <Button onClick={printTable} colorScheme="green" ml={5}>
          Print to PDF
        </Button> */}
      </Box>
      <TableContainer>
        <Table variant="striped" colorScheme="teal">
          <Thead>
            <Tr>
              <Th>Transaction Ref No</Th>
              <Th>Sales Contract No</Th>
              <Th>Exporter Name</Th>
              <Th>Invoice No</Th>
              <Th>Invoice Date</Th>
              <Th>Invoice Value</Th>
              <Th>Credit Taken Date</Th>
              <Th>Credit Period</Th>
              <Th>Credit Due Date</Th>
              <Th>Repayment Date</Th>
              <Th>Repayment Amount</Th>
              
              
              
              
            </Tr>
          </Thead>
          <Tbody>
            {newtableData.map((data, index) => (
              <Tr key={index}>
                <Td>{data.transactionRefNo || "N/A"}</Td>
                <Td>{data.salesContractNumber}</Td>
                <Td>{data.exporterName}</Td>
                <Td>{data.exporterInvoiceNo}</Td>
                <Td>{formatDate(data.invoiceDate)}</Td>
                <Td>{data.exporterInvoiceValue}</Td>
                <Td>{formatDate(data.creditTakenDate)}</Td>
                <Td>{data.creditPeriod}</Td>
                <Td>{formatDate(data.creditDueDate)}</Td>
                <Td>
                {formatDate(calculateRepaymentDate(data.creditTakenDate, data.creditPeriod))}
              </Td>
               <Td>{data.amountInINR}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );

  const renderChartReport = () => (
    <Grid templateColumns="repeat(3, 1fr)" gap={12} mt={10} mb={20}>
      {/* <GridItem>
          <Bar data={transformData("bar")} />
        </GridItem>
        <GridItem>
          <Line data={transformData("line")} />
        </GridItem> */}

      {/* <GridItem>
          <Bar data={chartData} />
        </GridItem>
        <GridItem>
          <Line data={chartData} />
        </GridItem> */}
      {/* <GridItem>
          <Bar data={mixedChartData} />
        </GridItem>
        <GridItem h={400}>
          <Text fontSize="xl" mb={4}>
            Sales Contract Quantity
          </Text>
          <Pie data={transformData("pie")} />
        </GridItem>
        <GridItem h={400}>
          <Text fontSize="xl" mb={4}>
            Sales Contract Total Rate
          </Text>
          <Pie data={chartData} />
        </GridItem> */}
      <GridItem h={400}>
        <Text fontSize="xl" mb={4}>
          Shipping Quantity
        </Text>
        <Pie data={transformShippingData()} />
      </GridItem>
      <GridItem h={400}>
        {chartFinancerData && (
          <>
            <Text fontSize="xl" mb={4}>
              Financer
            </Text>
            <Pie data={chartFinancerData} />
          </>
        )}

        {/* <Pie data={transformFinancerData("pie")} /> */}
      </GridItem>
    </Grid>
  );
  return (
    <Box
      pl={{ base: "2", md: "5" }}
      pt={{ base: "10", md: "10" }}
      w={{ base: "100%", md: "85%" }}
    >
      <Navbar />
      <br />
      <Flex mb={4} gap={4} wrap="wrap">
        <FormControl mb={4} w={200}>
          <FormLabel>Country of Origin</FormLabel>
          <SelectComponent
            options={[
              { label: "Select Country", value: "" },
              ...new Set(
                shippingDetailsData.map((item) => ({
                  label: item.shipmentWiseData.shipmentcountry,
                  value: item.shipmentWiseData.shipmentcountry,
                }))
              ),
            ]}
            onChange={(option) =>
              handleFilterChange("shipmentcountry", option.value)
            }
            placeholder="Select Country"
          />
        </FormControl>

        <FormControl mb={4} w={200}>
          <FormLabel>Exporter Name</FormLabel>
          <SelectComponent
            options={[
              { label: "Select Exporter Name", value: "" },
              ...new Set(
                shippingDetailsData.map((item) => ({
                  label: item.shipmentWiseData.exporterName,
                  value: item.shipmentWiseData.exporterName,
                }))
              ),
            ]}
            onChange={(option) =>
              handleFilterChange("exporterName", option.value)
            }
            placeholder="Select Exporter"
          />
        </FormControl>

        <FormControl mb={4} w={200}>
          <FormLabel>Clearing Agent</FormLabel>
          <SelectComponent
            options={[
              { label: "Select Clearing Agent", value: "" },
              ...new Set(
                shippingDetailsData.map((item) => ({
                  label: item.shipmentWiseData.clearingHouseAgentName,
                  value: item.shipmentWiseData.clearingHouseAgentName,
                }))
              ),
            ]}
            onChange={(option) =>
              handleFilterChange("clearingHouseAgentName", option.value)
            }
            placeholder="Clearing Agent"
          />
        </FormControl>
        <FormControl mb={4} w={200}>
          <FormLabel>Shipping Line</FormLabel>
          <SelectComponent
            options={[
              { label: "Select Shipping Line", value: "" },
              ...new Set(
                shippingDetailsData.map((item) => ({
                  label: item.shipmentWiseData.shippingLine,
                  value: item.shipmentWiseData.shippingLine,
                }))
              ),
            ]}
            onChange={(option) =>
              handleFilterChange("shippingLine", option.value)
            }
            placeholder="Shipping Line"
          />
        </FormControl>

        <FormControl mb={4} w={180}>
          <FormLabel>Type Of Duty</FormLabel>
          <SelectComponent
            options={[
              { label: "Select Type Of Duty", value: "" },
              ...new Set(
                shippingDetailsData.map((item) => ({
                  label: item.shipmentWiseData.typeOfDuty,
                  value: item.shipmentWiseData.typeOfDuty,
                }))
              ),
            ]}
            onChange={(option) =>
              handleFilterChange("typeOfDuty", option.value)
            }
            placeholder="Type Of Duty"
          />
        </FormControl>

        <FormControl mb={4} w={200}>
          <FormLabel>Bank Name</FormLabel>
          <SelectComponent
            options={[
              { label: "Select Bank Name", value: "" },
              ...new Set(
                shippingDetailsData.flatMap((item) =>
                  item.paymentStatusRows.map((row) => ({
                    label: row.paymentBankName,
                    value: row.paymentBankName,
                  }))
                )
              ),
            ]}
            onChange={(option) =>
              handleFilterChange("paymentBankName", option.value)
            }
            placeholder="Bank Name"
          />
        </FormControl>

        <FormControl mb={4} w={200}>
          <FormLabel>Estimated Time of Arrival</FormLabel>
          <DatePicker
            selected={filters.eta}
            onChange={(date) => handleFilterChange("eta", date)}
            placeholderText="Select Date"
            className="custom-datepicker"
          />
        </FormControl>
        <FormControl mb={4} w={180}>
          <FormLabel>Financer</FormLabel>
          <Select
            placeholder="Select Financer"
            onChange={(e) => setSelectedFinancer(e.target.value)}
            focusBorderColor="teal.400"
            borderColor="green.400"
          >
            {Array.from(
              new Set(
                fetchfinanceGenerationNamesList.map(
                  (data) => data.financerOrganizationName
                )
              )
            ).map((name) => (
              <option key={name} value={name}>
                {name}
              </option>
            ))}
          </Select>
        </FormControl>

        <Button
          onClick={clearFilters}
          mt={8}
          colorScheme="white"
          boxShadow={"md"}
        >
          <CloseIcon color="red" />
        </Button>
      </Flex>

      <Box mb={4}>
        <Tabs variant="soft-rounded" colorScheme="teal">
          <TabList>
            <Tab>Detail Report</Tab>
            <Tab>Report Summary</Tab>
            <Tab>Financer Data</Tab>
            {/* <Tab>Chart Data</Tab> */}
          </TabList>
          <TabPanels>
            <TabPanel>{renderDetailReport()}</TabPanel>
            <TabPanel>{renderSummaryReport()}</TabPanel>
             <TabPanel>{renderFinancerReport()}</TabPanel>
            {/* <TabPanel>{renderChartReport()}</TabPanel> */}
            {/* <TabPanel>
              <ReportsData />
            </TabPanel> */}
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
};

export default ReportsTable;
