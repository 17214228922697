import { Flex } from "@chakra-ui/react";
import React from "react";
import Sidebar from "../../../Components/Sidebar";
import ReportsData from "./ReportsData";
import ReportsTable from "./ReportsTable";

const Reports = () => {
  return (
    <Flex>
      <Sidebar />
      {/* <ReportsData /> */}
      <ReportsTable />
    </Flex>
  );
};

export default Reports;
