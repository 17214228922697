import React, { useState, useEffect } from "react";
import {
  Box,
  Select,
  FormControl,
  FormLabel,
  Flex,
  Grid,
  GridItem,
  Text,
} from "@chakra-ui/react";
import { Bar, Pie, Line } from "react-chartjs-2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./datepicker-custom.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useAuth } from "../../../Context/AuthProvider";
import Navbar from "../../../Components/Navbar";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const ReportsData = () => {
  const { salesContractData, shippingData, fetchfinanceGenerationNamesList } =
    useAuth();
  // console.log(
  //   "fetchfinanceGenerationNamesList",
  //   fetchfinanceGenerationNamesList
  // );
  const [exporter, setExporter] = useState("");
  const [importer, setImporter] = useState("");
  const [itemName, setItemName] = useState("");
  const [financer, setFinancer] = useState("");
  const [chartFinancerData, setChartFinancerData] = useState(null);
  const [paymentBankName, setPaymentBankName] = useState("");

  const [transporter, setTransporter] = useState("");
  const [dateRange, setDateRange] = useState([null, null]);
  const [filteredData, setFilteredData] = useState([]);

  const [startDate, endDate] = dateRange;

  // Filter data based on dropdown and date range
  useEffect(() => {
    const filtered = salesContractData.filter((data) => {
      const contractDate = new Date(data.salesContractDate);
      const withinDateRange =
        (!startDate ||
          contractDate >= new Date(startDate.setHours(0, 0, 0, 0))) &&
        (!endDate ||
          contractDate <= new Date(endDate.setHours(23, 59, 59, 999)));

      const matchesExporter = !exporter || data.exporterName === exporter;
      const matchesImporter = !importer || data.importingFirmName === importer;
      // const matchesFinancer = !financer || data.exporterName === financer;
      const matchesTransporter =
        !transporter ||
        shippingData
          .flatMap((shipment) =>
            shipment.rowsCCS.map((row) => row.transporterName)
          )
          .includes(transporter);
      const matchesItemName =
        !itemName ||
        shippingData
          .flatMap((shipment) => shipment.rows.map((row) => row.itemName))
          .includes(itemName);
      const matchesBank =
        !paymentBankName ||
        shippingData
          .flatMap((shipment) =>
            shipment.paymentStatusRows.map((row) => row.paymentBankName)
          )
          .includes(paymentBankName);

      return (
        withinDateRange &&
        matchesExporter &&
        matchesImporter &&
        matchesItemName &&
        matchesBank &&
        matchesTransporter
      );
    });

    setFilteredData(filtered);
  }, [
    exporter,
    importer,
    itemName,
    financer,
    paymentBankName,
    transporter,
    startDate,
    endDate,
    salesContractData,
    shippingData,
  ]);

  useEffect(() => {
    const chartData = transformFinancerData("pie", financer);
    setChartFinancerData(chartData);
  }, [financer, fetchfinanceGenerationNamesList]);

  const generateColors = (count) => {
    const colors = [];
    for (let i = 0; i < count; i++) {
      const r = Math.floor(Math.random() * 255);
      const g = Math.floor(Math.random() * 255);
      const b = Math.floor(Math.random() * 255);
      colors.push(`rgba(${r}, ${g}, ${b}, 0.6)`); // Background color with transparency
    }
    return colors;
  };

  const transformData = (chartType) => {
    const labels = filteredData.map((data) => data.salesContractNumber);
    const count = labels.length; // Number of data points
    const backgroundColors = generateColors(count); // Dynamic background colors
    const borderColors = backgroundColors.map(
      (color) => color.replace("0.6", "1") // Convert to opaque for borders
    );

    if (chartType === "pie") {
      // For Pie Chart: Show Quantity and Item Name
      const values = filteredData.map((data) => {
        if (data.fixedPriceRows.length > 0) {
          return data.fixedPriceRows.reduce(
            (sum, row) => sum + parseFloat(row.quantity || 0),
            0
          );
        }
        if (data.floatingPriceRows.length > 0) {
          return data.floatingPriceRows.reduce(
            (sum, row) => sum + parseFloat(row.quantity || 0),
            0
          );
        }
        return 0;
      });

      const itemLabels = filteredData.map((data) => {
        if (data.fixedPriceRows.length > 0) {
          return data.fixedPriceRows.map((row) => row.fixeditemName).join(", ");
        }
        if (data.floatingPriceRows.length > 0) {
          return data.floatingPriceRows
            .map((row) => row.floatingitemName)
            .join(", ");
        }
        return "No Items";
      });

      // Combine salesContractNumber and itemName for better labels
      const pieLabels = labels.map(
        (label, index) => `${label} (${itemLabels[index]})`
      );

      return {
        labels: pieLabels,
        datasets: [
          {
            label: "Quantity",
            data: values,
            backgroundColor: backgroundColors,
            borderColor: borderColors,
            borderWidth: 1,
          },
        ],
      };
    } else {
      // For Bar and Line Charts: Show Total Cost
      const values = filteredData.map((data) => {
        if (data.fixedPriceRows.length > 0) {
          return data.fixedPriceRows.reduce(
            (sum, row) => sum + parseFloat(row.price || 0),
            0
          );
        }
        if (data.floatingPriceRows.length > 0) {
          return data.floatingPriceRows.reduce(
            (sum, row) => sum + parseFloat(row.finalPrice || 0),
            0
          );
        }
        return 0;
      });

      return {
        labels,
        datasets: [
          {
            label: "Total Cost",
            data: values,
            backgroundColor: backgroundColors,
            borderColor: borderColors,
            borderWidth: 1,
          },
        ],
      };
    }
  };

  const chartData = transformData();

  const mixedChartData = {
    labels: chartData.labels,
    datasets: [
      {
        type: "line",
        label: "Line Data",
        data: chartData.datasets[0].data,
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 2,
      },
      {
        type: "bar",
        label: "Bar Data",
        data: chartData.datasets[0].data,
        backgroundColor: "rgba(255, 99, 132, 0.6)",
      },
    ],
  };

  const transformShippingData = () => {
    const labels = shippingData.flatMap((shipment) =>
      shipment.rows.map((row) => row.itemName)
    );

    const values = shippingData.flatMap((shipment) =>
      shipment.rows.map((row) => parseFloat(row.quantity || 0))
    );

    const rates = shippingData.flatMap((shipment) =>
      shipment.rows.map((row) => parseFloat(row.rate || 0))
    );

    const invoiceValues = shippingData.flatMap((shipment) =>
      shipment.rows.map((row) => parseFloat(row.invoiceValueExporter || 0))
    );

    const count = labels.length; // Number of data points
    const backgroundColors = generateColors(count); // Dynamic background colors
    const borderColors = backgroundColors.map(
      (color) => color.replace("0.6", "1") // Convert to opaque for borders
    );

    // Enhanced labels with details for better display
    const pieLabels = labels.map(
      (label, index) =>
        `${label} (Qty: ${values[index]},Value: ${invoiceValues[index]}), Rate: ${rates[index]}`
    );

    return {
      labels: pieLabels,
      datasets: [
        {
          label: "Shipping Quantity",
          data: values,
          backgroundColor: backgroundColors,
          borderColor: borderColors,
          borderWidth: 1,
        },
      ],
    };
  };

  // const transformFinancerData = (chartType) => {
  //   // Extract labels (exporter names) and values (invoice values)
  //   const labels = fetchfinanceGenerationNamesList.map(
  //     (data) => data.exporterName
  //   );

  //   const values = fetchfinanceGenerationNamesList.map((data) =>
  //     parseFloat(data.exporterInvoiceValue || 0)
  //   );

  //   const count = labels.length; // Number of data points
  //   const backgroundColors = generateColors(count); // Dynamic background colors
  //   const borderColors = backgroundColors.map(
  //     (color) => color.replace("0.6", "1") // Convert to opaque for borders
  //   );

  //   if (chartType === "pie") {
  //     // For Pie Chart: Combine Exporter Name and Invoice Value
  //     const pieLabels = labels.map(
  //       (label, index) => `${label} (Invoice: $${values[index].toFixed(2)})`
  //     );

  //     return {
  //       labels: pieLabels,
  //       datasets: [
  //         {
  //           label: "Exporter Invoice Value",
  //           data: values,
  //           backgroundColor: backgroundColors,
  //           borderColor: borderColors,
  //           borderWidth: 1,
  //         },
  //       ],
  //     };
  //   } else {
  //     // For Bar and Line Charts: Show Total Invoice Value
  //     return {
  //       //labels,
  //       datasets: [
  //         {
  //           label: "Exporter Invoice Value",
  //           data: values,
  //           backgroundColor: backgroundColors,
  //           borderColor: borderColors,
  //           borderWidth: 1,
  //         },
  //       ],
  //     };
  //   }
  // };

  const transformFinancerData = (chartType, financer) => {
    // Filter data based on selected financer
    const filteredData = financer
      ? fetchfinanceGenerationNamesList.filter(
          (data) => data.financerOrganizationName === financer
        )
      : fetchfinanceGenerationNamesList;

    // Extract labels (exporter names) and values (invoice values)
    const labels = filteredData.map((data) => data.exporterName);
    const values = filteredData.map((data) =>
      parseFloat(data.exporterInvoiceValue || 0)
    );

    const count = labels.length; // Number of data points
    const backgroundColors = generateColors(count); // Dynamic background colors
    const borderColors = backgroundColors.map(
      (color) => color.replace("0.6", "1") // Convert to opaque for borders
    );

    if (chartType === "pie") {
      // For Pie Chart: Combine Exporter Name and Invoice Value
      const pieLabels = labels.map(
        (label, index) => `${label} (Invoice: ${values[index].toFixed(2)})`
      );

      return {
        labels: pieLabels,
        datasets: [
          {
            label: "Exporter Invoice Value",
            data: values,
            backgroundColor: backgroundColors,
            borderColor: borderColors,
            borderWidth: 1,
          },
        ],
      };
    } else {
      // For Bar and Line Charts: Show Total Invoice Value
      return {
        labels,
        datasets: [
          {
            label: "Exporter Invoice Value",
            data: values,
            backgroundColor: backgroundColors,
            borderColor: borderColors,
            borderWidth: 1,
          },
        ],
      };
    }
  };

  return (
    // <Box
    //   pl={{ base: "2", md: "10" }}
    //   pt={{ base: "10", md: "10" }}
    //   w={{ base: "100%", md: "80%" }}
    // >
    //   <Navbar />
    //   <br />
      <Box>
      <Flex mb={4} gap={4} wrap="wrap">
        <FormControl w={200}>
          <FormLabel>Exporter</FormLabel>
          <Select
            placeholder="Select Exporter"
            onChange={(e) => setExporter(e.target.value)}
            focusBorderColor="teal.400"
            borderColor="green.400"
          >
            {Array.from(
              new Set(salesContractData.map((data) => data.exporterName))
            ).map((name) => (
              <option key={name} value={name}>
                {name}
              </option>
            ))}
          </Select>
        </FormControl>
        <FormControl w={200}>
          <FormLabel>Financer</FormLabel>
          <Select
            placeholder="Select Financer"
            onChange={(e) => setFinancer(e.target.value)}
            focusBorderColor="teal.400"
            borderColor="green.400"
          >
            {Array.from(
              new Set(
                fetchfinanceGenerationNamesList.map(
                  (data) => data.financerOrganizationName
                )
              )
            ).map((name) => (
              <option key={name} value={name}>
                {name}
              </option>
            ))}
          </Select>
        </FormControl>
        <FormControl w={200}>
          <FormLabel>Importer</FormLabel>
          <Select
            placeholder="Select Importer"
            onChange={(e) => setImporter(e.target.value)}
            focusBorderColor="teal.400"
            borderColor="green.400"
          >
            {Array.from(
              new Set(salesContractData.map((data) => data.importingFirmName))
            ).map((name) => (
              <option key={name} value={name}>
                {name}
              </option>
            ))}
          </Select>
        </FormControl>

        <FormControl w={200}>
          <FormLabel>Item Name</FormLabel>
          <Select
            placeholder="Select Item"
            onChange={(e) => setItemName(e.target.value)}
            focusBorderColor="teal.400"
            borderColor="green.400"
          >
            {Array.from(
              new Set(
                shippingData.flatMap((shipment) =>
                  shipment.rows.map((row) => row.itemName)
                )
              )
            ).map((name) => (
              <option key={name} value={name}>
                {name}
              </option>
            ))}
          </Select>
        </FormControl>

        <FormControl w={200}>
          <FormLabel>Bank Name</FormLabel>
          <Select
            placeholder="Select Bank"
            onChange={(e) => setPaymentBankName(e.target.value)}
            focusBorderColor="teal.400"
            borderColor="green.400"
          >
            {Array.from(
              new Set(
                shippingData.flatMap((shipment) =>
                  shipment.paymentStatusRows.map((row) => row.paymentBankName)
                )
              )
            ).map((name) => (
              <option key={name} value={name}>
                {name}
              </option>
            ))}
          </Select>
        </FormControl>

        <FormControl w={200}>
          <FormLabel>Transporter</FormLabel>
          <Select
            placeholder="Select Transporter"
            onChange={(e) => setTransporter(e.target.value)}
            focusBorderColor="teal.400"
            borderColor="green.400"
          >
            {Array.from(
              new Set(
                shippingData.flatMap((shipment) =>
                  shipment.rowsCCS.map((row) => row.transporterName)
                )
              )
            ).map((name) => (
              <option key={name} value={name}>
                {name}
              </option>
            ))}
          </Select>
        </FormControl>

        <FormControl w={200}>
          <FormLabel>Date Range</FormLabel>
          <Flex gap={2}>
            <DatePicker
              selected={startDate}
              onChange={(update) => setDateRange([update, endDate])}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              placeholderText="Start Date"
              className="custom-datepicker"
            />
            <DatePicker
              selected={endDate}
              onChange={(update) => setDateRange([startDate, update])}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              placeholderText="End Date"
              className="custom-datepicker"
            />
          </Flex>
        </FormControl>
      </Flex>

     
      <Grid templateColumns="repeat(3, 1fr)" gap={12} mt={10} mb={20}>
        <GridItem>
          <Bar data={transformData("bar")} />
        </GridItem>
        <GridItem>
          <Line data={transformData("line")} />
        </GridItem>

        {/* <GridItem>
          <Bar data={chartData} />
        </GridItem>
        <GridItem>
          <Line data={chartData} />
        </GridItem> */}
        <GridItem>
          <Bar data={mixedChartData} />
        </GridItem>
        <GridItem h={400}>
          <Text fontSize="xl" mb={4}>
            Sales Contract Quantity
          </Text>
          <Pie data={transformData("pie")} />
        </GridItem>
        <GridItem h={400}>
          <Text fontSize="xl" mb={4}>
            Sales Contract Total Rate
          </Text>
          <Pie data={chartData} />
        </GridItem>
        <GridItem h={400}>
          <Text fontSize="xl" mb={4}>
            Shipping Quantity
          </Text>
          <Pie data={transformShippingData()} />
        </GridItem>
        <GridItem h={400}>
          {chartFinancerData && (
            <>
              <Text fontSize="xl" mb={4}>
                Financer
              </Text>
              <Pie data={chartFinancerData} />
            </>
          )}

          {/* <Pie data={transformFinancerData("pie")} /> */}
        </GridItem>
      </Grid>
    </Box>
   
  );
};

export default ReportsData;
